import React from 'react';
import PropTypes from 'prop-types';

import PeopleCard from '../Cards/PeopleCard';

import style from './CorporateContacts.module.scss';

const CorporateContacts = ({ contents }) => {
    const { title, items } = contents;
    return (
        <div className={[style.container, 'container'].join(' ')}>
            <div className={style.headingText}>
                <h2>{title}</h2>
            </div>

            {/* region-details */}
            <div className={style.peopleCardWrapper}>
                {items.map((item, id) => {
                    return (
                        <div key={id} className={style.peopleCards}>
                            <PeopleCard {...item} title={item.role} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
CorporateContacts.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    role: PropTypes.string,
    contents: PropTypes.any,
    items: PropTypes.string,
};

export default CorporateContacts;
