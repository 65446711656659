import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { SmartLink } from '../SmartLink';

import style from './OurOffices.module.scss';

const OurOffices = ({ contents, popup }) => {
    const { title, regions } = contents;
    const [index, setIndex] = useState(0);
    const [currentTitle, setCurrentTitle] = useState(regions[0].name);
    const [officeList, setOfficeList] = useState(regions[0].offices);

    const router = useRouter();

    return (
        <div className={[style.container, 'container'].join(' ')}>
            <div className={style.officeWrapper}>
                {/* name */}
                <div className={style.leftItem}>
                    <h2>{title}</h2>
                    <ul className={style.regionWrapper}>
                        {regions.map(({ name, offices }, id) => (
                            <li
                                className={style.regionName}
                                onClick={() => {
                                    setIndex(id);
                                    setOfficeList(offices);
                                    setCurrentTitle(name);
                                }}
                                key={id}
                            >
                                <span className={['h3', index === id ? style.isActive : ''].join(' ')}>{name}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* region-details */}
                <div className={style.rightItem}>
                    <div className={style.selcetedRegionName}>
                        <h3>{currentTitle}</h3>
                    </div>
                    <div className={style.addressCards}>
                        {officeList.map(({ name, subtitle, address, tel, fax, link }, idx) => (
                            <div key={idx} className={`item-${idx}`}>
                                <h4>
                                    {name}
                                    {subtitle && (
                                        <>
                                            <br />
                                            <span>{subtitle}</span>
                                        </>
                                    )}
                                </h4>
                                <div
                                    className={style.address}
                                    dangerouslySetInnerHTML={{
                                        __html: address,
                                    }}
                                />
                                {tel.value && <p className={style.tel}>
                                    <a href={`tel:${tel.value}`}>
                                        {tel.title} {tel.value}
                                    </a>
                                </p>}
                                {fax.value && <p>
                                    {fax.title} {fax.value}
                                </p>}
                                {link && (
                                    <div className={style.gotoLink}>
                                        {popup ? (
                                            <SmartLink
                                                href={`${router.asPath}?pageId=${link.link.url.replace(/\/$/, '')}`}
                                                className='arrow-link'
                                                hasArrow
                                                as={link.link.url}
                                            >
                                                {link.text}
                                            </SmartLink>
                                        ) : (
                                            <SmartLink href={link.link.url} className='arrow-link' hasArrow>
                                                {link.text}
                                            </SmartLink>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

OurOffices.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    contents: PropTypes.any,
    popup: PropTypes.bool,
};
export default OurOffices;
