import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './GlobalMediaContacts.module.scss';

const GlobalMediaContacts = ({ contents }) => {
    // console.log(contents);
    const { title, regions } = contents;
    const [officeList, setOfficeList] = useState(regions[0].offices);
    // const [currentTitle, setCurrentTitle] = useState(regions[0].name);
    const [index, setIndex] = useState(0);

    return (
        <div className={[style.container, 'container'].join(' ')}>
            <div className={style.officeWrapper}>
                <div className={style.leftItem}>
                    <h2>{title}</h2>
                    <ul className={style.regionWrapper}>
                        {regions.map(({ name, offices }, id) => (
                            <li
                                className={style.regionName}
                                onClick={() => {
                                    setOfficeList(offices);
                                    setIndex(id);
                                    // setCurrentTitle(name);
                                }}
                                key={id}
                            >
                                <span className={['h3', index === id ? style.isActive : ''].join(' ')}>{name}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* region-details */}
                <div className={style.rightItem}>
                    {/* <div className={style.selcetedRegionName}>
                        <h3>{currentTitle}</h3>
                    </div> */}
                    <div className={style.addressCards}>
                        {officeList.map(({ name, contactName, contactRole, tel, emailTitle, email }, id) => (
                            <div key={id} className={`item-${id}`}>
                                <h4>{name}</h4>
                                <div className={style.contactDetails}>
                                    <p className='orangeClr'>{contactName}</p>
                                    <p className='orangeClr'>{contactRole}</p>
                                </div>
                                <div className={style.tel}>
                                    <a href={`tel:${tel}`}>
                                        <p>Tel: {tel}</p>
                                    </a>
                                </div>
                                <div>
                                    <p>{emailTitle}</p>
                                </div>
                                <div>
                                    <p>
                                        {email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
                                            ? <a href={`mailto:${email}`}>{email}</a>
                                            : <>{email}</>
                                        }
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

GlobalMediaContacts.propTypes = {
    name: PropTypes.string,
    contactName: PropTypes.string,
    contactRole: PropTypes.string,
    tel: PropTypes.number,
    emailTitle: PropTypes.string,
    email: PropTypes.string,
    regions: PropTypes.string,
    title: PropTypes.string,
    contents: PropTypes.any,
};
export default GlobalMediaContacts;
